import { useEffect, useRef, useState } from "react";
import { useObjectivesInput } from "./atom";
import { csSectionTitle } from "../../components/styles";
import classNames from "classnames";
import { ObjectivePreset } from "../../app/models";

type ObjectivePresetButtonProps = {
  preset: ObjectivePreset;
  onClick: () => void;
};

function ObjectivePresetButton(props: ObjectivePresetButtonProps) {
  const { preset, onClick } = props;

  const csButton = classNames(
    'rounded-box', 'border-2', 'p-2',
    'select-none', 'cursor-pointer',
    'border-neutral',
  );
  const csTitle = classNames(
    'text-[16px]',
    'font-semibold',
    'mb-2',
    `text-${preset.uiVariantColor}`
  );

  return (
    <div className={csButton} onClick={onClick}>
      <h4 className={csTitle}>{preset.title}</h4>
      <p className="text-[14px] font-light text-primary-content">{preset.description}</p>
    </div>
  );
}

export type ObjectivePresetsModalProps = {
  presets: ObjectivePreset[];
  initialPreset?: ObjectivePreset;
}

export function ObjectivePresetsModal(props: ObjectivePresetsModalProps) {
  const { presets, initialPreset } = props;
  const [objective, setObjective] = useObjectivesInput();
  const [selectedPreset, setSelectedPreset] = useState<ObjectivePreset | null>(null);
  const modalRef = useRef<HTMLDialogElement | null>(null);

  const modalButtonText = selectedPreset ? selectedPreset.title : 'PRESETS';
  const isUsingObjectivePreset = !!selectedPreset && objective === selectedPreset.promptText;
  const csModalButton = classNames(
    'btn', 'btn-outline',
    'h-[24px]', 'min-h-[24px]', 'px-2', 'm-0',
    {
      [`btn-${selectedPreset?.uiVariantColor}`]: isUsingObjectivePreset,
      'btn-neutral': !isUsingObjectivePreset,
    }
  )

  useEffect(() => {
    if (initialPreset) {
      setObjective(initialPreset.promptText);
      setSelectedPreset(initialPreset);
    }
  }, [initialPreset]); // eslint-disable-line react-hooks/exhaustive-deps

  const presetItems = presets.map((preset) => (
    <ObjectivePresetButton
      key={preset.id}
      preset={preset}
      onClick={() => {
        setObjective(preset.promptText);
        setSelectedPreset(preset);
        modalRef.current?.close();
      }}
    />
  ))

  return (
    <div className='ml-4'>
      <button
        className={csModalButton}
        onClick={() => modalRef.current?.showModal()}
      >
        {modalButtonText}
      </button>
      <dialog ref={modalRef} className="modal">
        <div className="modal-box px-0">
          <h3 className="font-bold text-lg mb-4 px-4">Objective Presets</h3>
          <div className="max-h-[30vh] overflow-scroll flex flex-col gap-2 px-4">
            {presetItems}
          </div>
        </div>
        <form method="dialog" className="modal-backdrop">
          <button>close</button>
        </form>
      </dialog>
    </div>
  );
}

export type ObjectivesInputProps = ObjectivePresetsModalProps & {};

export function ObjectivesInput(props: ObjectivesInputProps) {
  const [objectives, setObjectives] = useObjectivesInput();

  return (
    <div className='px-[1rem] mt-2'>
      <div className='flex items-center mb-2'>
        <h3 className={csSectionTitle()}>Objectives</h3>
        <ObjectivePresetsModal {...props} />
      </div>
      <textarea
        name="objectives"
        id="objectives"
        rows={3}
        className='textarea textarea-bordered textarea-ghost w-full rounded-[4px]  outline-none mt-[8px]'
        value={objectives}
        onChange={(e) => setObjectives(e.target.value)}
      ></textarea>
    </div>
  );
}