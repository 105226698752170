import PageNav from '../../components/PageNav';
import classNames from 'classnames';
import { SourceTab, useFlowStateValue, useFlowStateOp } from './atom';
import SourceScreenshot, { SourceScreenshotAdd } from './SourceScreenshot';
import { useEffect, useRef } from 'react';
import { csSectionTitle } from '../../components/styles';
import { IconAdjustmentsAlt } from '@tabler/icons-react';
import { ObjectivesInput } from './Objectives';
import VideoInput from './VideoInput';
import { useLoaderData } from 'react-router-dom';
import { FlowScreenshotData } from './loader';
import { FlowScreenshotOutputSection } from './models';
import Toasts from '../../components/Toast';
import { Tool } from '../../app/models';

type SourceTabHeaderProps = {
  activeTab: SourceTab;
  setActiveTab: (tab: SourceTab) => void;
};

function SourceTabHeader(props: SourceTabHeaderProps) {
  const {
    activeTab,
    setActiveTab,
  } = props;

  const csTab = csSectionTitle(
    'btn',
    'btn-ghost',
    'no-animation',
    'p-0',
    'outline-none',
  )

  const csTabActive = (t: SourceTab) => classNames(
    {
      'text-primary-content': activeTab === t,
      'text-secondary-content': activeTab !== t,
    }
  )

  const csPhotosTab = classNames(
    csTab,
    csTabActive(SourceTab.Photos),
  )
  const csVideoTab = classNames(
    csTab,
    csTabActive(SourceTab.Video),
  )

  return (
    <div className='flex text-left font-semibold px-[1rem] gap-[1rem]'>
      <button className={csPhotosTab}
        onClick={() => setActiveTab(SourceTab.Photos)}
      >
        Photos
      </button>
      <button className={csVideoTab}
        onClick={() => setActiveTab(SourceTab.Video)}
      >
        Video
      </button>
    </div>
  );
}

function ImageInput() {
  const { loadedImages } = useFlowStateValue();

  const imagesWord = loadedImages.length === 1 ? 'image' : 'images';

  return (
    <div className='flex items-center gap-[8px] px-[1rem]'>
      <div className="flex-1 py-[4px] flex items-center gap-[8px]">
        <p className='text-primary-content font-medium text-[12px]'>
          🎞️
        </p>
        <p className='text-secondary-content font-regular text-[12px]'>
          {loadedImages.length} {imagesWord}
        </p>
      </div>
    </div>
  );
}

function SourceItems() {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const { loadedImages, activeTab } = useFlowStateValue();
  const op = useFlowStateOp();

  const screenshots = loadedImages.map((image, index) => (
    <SourceScreenshot
      key={index}
      image={image}
      onClick={() => { }}
      onRemove={() => { op.removeImage(image) }}
      parentRef={containerRef}
    />
  ));

  return (
    <div className='relative flex gap-[1rem] pb-2 max-w-[200vw] overflow-x-scroll' ref={containerRef}>
      <div></div> {/* FIXME: hack for leading gap */}
      {screenshots}
      {activeTab === SourceTab.Photos
        && (
          <SourceScreenshotAdd
            onAddImage={op.addImage}
            enabled={op.canUpdateImages()}
          />
        )
      }
      <div></div> {/* FIXME: hack for trailing gap */}
    </div>
  );
}

type AnalyzeButtonProps = {
  selectedTool: Tool;
};

function AnalyzeButton(props: AnalyzeButtonProps) {
  const { selectedTool } = props;
  const op = useFlowStateOp();

  const csButton = classNames(
    'btn btn-accent outline-none min-w-[108px] h-[38px] min-h-[38px]',
    'text-accent-content text-[14px] font-semibold leading-none',
    {
      'btn-disabled': !op.canRunAnalyze(),
    },
  )

  return (
    <div className='flex px-[1rem] mt-4 items-center'>
      <button
        className={csButton}
        onClick={() => {
          if (!op.canRunAnalyze()) {
            return;
          }
          op.runAnalyze(selectedTool);
        }}
      >
        Go
      </button>
      <button className="btn btn-ghost ml-2 p-0 hidden">
        <IconAdjustmentsAlt
          size="24px"
          className='text-secondary-content'
        />
      </button>
    </div>
  );
}

type AnalyzeResultParagraphProps = {
  title: string;
  content: string;
};

function AnalyzeResultParagraph(props: AnalyzeResultParagraphProps) {
  const { title, content } = props;
  const op = useFlowStateOp();

  const contentLines = content.split('\n').map((p, idx) => {
    return (
      <p key={`line-${idx}`} className='font-regular text-[15px] text-primary-content'>
        {p}
      </p>
    );
  });

  return (
    <div className='mt-2'>
      <h3 className={csSectionTitle('mb-2', 'flex', 'items-center')}>
        <span className='flex-1'>
          {title}
        </span>
        <span
          className='btn btn-ghost btn-outline h-[20px] min-h-[20px] px-2 m-0 mr-2'
          onClick={() => {
            navigator.clipboard.writeText(content);
            op.addToast('Copied to clipboard');
          }}
        >
          copy
        </span>
      </h3>
      {contentLines}
    </div>
  );
}

type AnalyzeResultActionButtonProps = {
  title: string;
  onClick: () => void;
  variant: 'info' | 'warning' | 'default';
};

function AnalyzeResultActionButton(props: AnalyzeResultActionButtonProps) {
  const { title, onClick, variant } = props;

  const csButton = classNames(
    'btn',
    'btn-sm',
    'h-[2.5rem] min-h-[2.5rem]',
    {
      'btn-info': variant === 'info',
      'btn-warning': variant === 'warning',
      'btn-neutral': variant === 'default',
    }
  );

  return (
    <button className={csButton} onClick={onClick}>
      {title}
    </button>
  );
}

type AnalyzeResultParagraphsProps = {
  sections: FlowScreenshotOutputSection[];
};

function AnalyzeResultParagraphs(props: AnalyzeResultParagraphsProps) {
  const { sections } = props;

  return (
    <div>
      {sections.map((section, idx) => (
        <AnalyzeResultParagraph
          key={`section-${idx}`}
          title={section.title}
          content={section.content}
        />
      ))}
      <div className='mt-8 flex flex-col gap-2'>
        <AnalyzeResultActionButton
          title='Too much...'
          variant='info'
          onClick={() => { }}
        />
        <AnalyzeResultActionButton
          title='Try something stronger'
          variant='warning'
          onClick={() => { }}
        />
        <AnalyzeResultActionButton
          title='Save'
          variant='default'
          onClick={() => { }}
        />
      </div>
    </div>
  );
}

type AnalyzeResultErrorProps = {
  content: string;
};

function AnalyzeResultError(props: AnalyzeResultErrorProps) {
  const { content } = props;

  return (
    <div>
      <h3 className={csSectionTitle('mb-4')}>
        O_o Something went wrong
      </h3>
      <pre className='font-regular text-[15px] rounded-box bg-neutral p-2 h-[120px]'>
        {content}
      </pre>
    </div>
  );
}

function AnalyzeResultLoadingSkeleton() {
  return (
    <div className='flex flex-col gap-[1rem]'>
      <div className='skeleton w-[30%] h-[20px] bg-base-100'></div>
      <div className='skeleton w-full h-[20px] bg-base-100'></div>
      <div className='skeleton w-[85%] h-[20px] bg-base-100'></div>
      <div className='skeleton w-[65%] h-[20px] bg-base-100'></div>
    </div>
  );
}

function AnalyzeResults() {
  const containerRef = useRef<HTMLDivElement>(null);
  const { runningAnalyze, output, outputError } = useFlowStateValue();

  const showOutput = output && !runningAnalyze && !outputError;
  const csContainer = classNames(
    // NOTE: set padding --nav-header-height to allow scrolling to the top of the first section
    'flex flex-col gap-4 px-[1rem] pt-[calc(var(--nav-header-height))]',
    {
      'pb-8': !showOutput,
      'h-[--content-height-with-nav-header-offset]': showOutput,
    },
  );

  useEffect(() => {
    if (output || outputError || runningAnalyze) {
      containerRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [output, outputError, runningAnalyze]);


  return (
    <div className={csContainer} ref={containerRef}>
      {showOutput && <AnalyzeResultParagraphs sections={output.sections} />}
      {outputError && <AnalyzeResultError content={outputError} />}
      {runningAnalyze && <AnalyzeResultLoadingSkeleton />}
    </div>
  )
}

function FlowBody() {
  const { selectedTool } = useLoaderData() as FlowScreenshotData;
  const { activeTab } = useFlowStateValue();
  const op = useFlowStateOp();

  return (
    <div className='max-w-[100vw]'>
      <SourceTabHeader
        activeTab={activeTab}
        setActiveTab={op.setActiveTab}
      />
      {activeTab === SourceTab.Photos && <ImageInput />}
      {activeTab === SourceTab.Video && <VideoInput
        onLoadedImages={(images) => {
          op.setImages(images);
        }}
      />}
      <SourceItems />
      <ObjectivesInput
        presets={selectedTool.objectivePresets}
        initialPreset={selectedTool.objectivePresets.find(() => true)}
      />
      <AnalyzeButton selectedTool={selectedTool} />
      <AnalyzeResults />
    </div>
  );
}

export default function FlowScreenshot() {
  const { appSettings, selectedTool } = useLoaderData() as FlowScreenshotData;
  const { toasts } = useFlowStateValue();
  const op = useFlowStateOp();

  return (
    <div className="relative max-w-[100vw] w-[100vw] h-[100vh] max-h-[100vh] overflow-y-scroll overflow-x-hidden bg-primary pb-8">
      <PageNav
        title={selectedTool?.displayName || ''}
        tools={appSettings.tools}
        selectedToolId={selectedTool?.id || null}
      />
      <FlowBody />
      <Toasts
        messages={toasts}
        removeToast={op.removeToast}
      />
    </div>
  );
}