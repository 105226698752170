import { atom, useAtom, useAtomValue } from "jotai";
import { FlowScreenshotAPI } from "./api";
import { FlowScreenshotInput, FlowScreenshotOutput } from "./models";
import { ToastMessage } from "../../components/Toast";
import { Tool } from "../../app/models";

export enum SourceTab {
  Photos = "photos",
  Video = "video",
}

export type LoadedImage = {
  name: string;
  base64Data: string;
  mime: string;
  caption?: string;
};

export type FlowState = {
  apiClient: FlowScreenshotAPI;
  toasts: ToastMessage[];

  // inputs
  activeTab: SourceTab;
  loadedImages: LoadedImage[];
  objectives: string;

  // outputs
  runningAnalyze: boolean;
  output: FlowScreenshotOutput | null;
  outputError: string | null;
};

function defaultFlowState(): FlowState {
  return {
    apiClient: new FlowScreenshotAPI(),
    toasts: [],

    activeTab: SourceTab.Photos,
    loadedImages: [],
    objectives: "",

    runningAnalyze: false,
    output: null,
    outputError: null,
  };
}

const flowStateAtom = atom<FlowState>(defaultFlowState());

export function useFlowStateValue() {
  return useAtomValue(flowStateAtom);
}

export function useObjectivesInput() {
  const [state, setFlowState] = useAtom(flowStateAtom);

  return [
    state.objectives,
    (objectives: string) => {
      setFlowState((state) => ({
        ...state,
        objectives,
      }));
    },
  ] as const;
}

export function useFlowStateOp() {
  const [state, setFlowState] = useAtom(flowStateAtom);

  return {
    setActiveTab: (tab: SourceTab) => {
      setFlowState((state) => ({
        ...state,
        activeTab: tab,
      }));
    },

    canRunAnalyze: () => {
      if (state.loadedImages.length < 1) {
        return false;
      }
      if (state.objectives === "") {
        return false;
      }
      if (state.runningAnalyze) {
        return false;
      }

      return true;
    },

    runAnalyze: async (tool: Tool) => {
      const { apiClient } = state;

      const input: FlowScreenshotInput = {
        toolId: tool.id,
        objectives: state.objectives,
        photos: state.loadedImages.map((image) => ({
          name: image.name,
          photoBase64Data: image.base64Data,
        })),
      };

      setFlowState((state) => ({
        ...state,
        runningAnalyze: true,
        outputError: null,
      }));

      const resp = await apiClient.openai(input);

      if ("error" in resp) {
        setFlowState((state) => ({
          ...state,
          runningAnalyze: false,
          output: null,
          outputError: resp.error.message,
        }));
        return;
      }
      if ("data" in resp) {
        setFlowState((state) => ({
          ...state,
          runningAnalyze: false,
          output: resp.data,
          outputError: null,
        }));
        return;
      }
      setFlowState((state) => ({
        ...state,
        runningAnalyze: false,
        output: null,
        outputError: "Unknown response",
      }));
    },

    canUpdateImages: () => {
      if (state.runningAnalyze) {
        return false;
      }

      return true;
    },

    addImage(image: LoadedImage) {
      setFlowState((state) => {
        if (state.loadedImages.find((i) => i.name === image.name)) {
          return state;
        }

        return {
          ...state,
          loadedImages: [...state.loadedImages, image],
        };
      });
    },

    removeImage(image: LoadedImage) {
      setFlowState((state) => {
        return {
          ...state,
          loadedImages: state.loadedImages.filter((i) => i.name !== image.name),
        };
      });
    },

    setImages(images: LoadedImage[]) {
      setFlowState((state) => ({
        ...state,
        loadedImages: images,
      }));
    },

    addToast(content: string) {
      setFlowState((state) => {
        if (state.toasts.length > 10) {
          // too many toasts, ignore new ones
          return state;
        }

        const message = {
          id: Math.random().toString(),
          content,
          durationInMilliseconds: 3000,
        };

        return {
          ...state,
          toasts: [...state.toasts, message],
        };
      });
    },

    removeToast(message: ToastMessage) {
      setFlowState((state) => ({
        ...state,
        toasts: state.toasts.filter((m) => m.id !== message.id),
      }));
    },
  };
}
