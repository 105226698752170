import { Link } from "react-router-dom";
import { useAuth } from "../../components/AuthProvider";
import { Session } from "@supabase/supabase-js";
import { resolvePagesAPIbase } from "../../api/settings";
import { createRef, useEffect, useState } from "react";
import classNames from "classnames";
import preval from 'preval.macro';
import "./style.css";
import drakePostingYes from './drakeposting-yes.png'
import drakePostingNo from './drakeposting-no.png'
import ickAvatar from './ick-avatar.webp'

async function getMoney(session: Session) {
  const apiUrl = resolvePagesAPIbase('api', 'payment', 'v1', 'stripe', 'create-checkout');
  const httpResp = await fetch(apiUrl, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${session.access_token}`,
    },
  });

  const resp = await httpResp.json();
  console.log(resp);

  return (resp as { data: { url: string } }).data.url;
}

function LandingAfterLogin() {
  const { user, session } = useAuth();

  console.log(session);

  return (
    <div className='flex flex-col items-center mt-4 gap-2'>
      {user && <p>hello, {user.email}</p>}
      {!user && <Link to='/auth' className="btn btn-info btn-wide">sign in</Link>}
      <Link to='/tools/start' className="btn btn-error btn-wide">use tools</Link>
      <button className="btn btn-accent btn-wide" onClick={async () => {
        if (!session) {
          return;
        }

        const url = await getMoney(session);
        window.location.href = url;
      }}>give me money</button>
    </div>
  );
}

const gradientPresets = [
  'bg-gradient-to-r from-cyan-500 to-blue-500',
  'bg-gradient-to-r from-indigo-400 to-cyan-400',
  'bg-gradient-to-r from-indigo-500 to-blue-500',
];

type StartUsingButtonProps = {
  heightButton: string;
  heightDivider: string;
  topOffset: string;
  gradient: string;
  text: string;
};

function StartUsingButton(props: StartUsingButtonProps) {
  const {
    heightButton,
    heightDivider,
    gradient,
    topOffset,
    text,
  } = props;

  const csButton = classNames(
    'block', 'sticky',
    'flex', 'items-center', 'justify-center',
    'text-accent-content', 'text-xl', 'font-semibold', 'px-4',
    'select-none', 'cursor-pointer',
    gradient,
  );

  return (
    <>
      <Link to="/tools/start" role="button" className={csButton} style={{
        top: `${topOffset}`,
        height: heightButton,
      }}>
        <span className="flex-1">
          {text}
        </span>
        <span className="flex-none">
          ➡️
        </span>
      </Link>
      <div style={{
        width: '100%',
        height: heightDivider,
      }}></div>
    </>
  );
}

type StartUsingButtonsProps = {
  initialOffset: string;
  buttons: { text: string }[];
}

function StartUsingButtons(props: StartUsingButtonsProps) {
  const { buttons, initialOffset } = props;

  const heightButton = 60;

  return (
    <>
      {buttons.map((button, idx) => {
        return (
          <StartUsingButton
            key={idx}
            heightButton={`${heightButton}px`}
            heightDivider="50vh"
            topOffset={`calc(${initialOffset} + ${idx * (heightButton + 2)}px)`}
            text={button.text}
            gradient={gradientPresets[idx % gradientPresets.length]}
          />
        );
      })}
      <div className="h-screen"></div>
    </>
  );
}

function DrakePostingMeme() {
  return (
    <div>
      <div className="flex flex-col px-4 gap-y-1 text-black font-light">
        <div className="flex gap-x-2 items-start align-top bg-white drop-shadow-sm rounded-md max-h-[80px] p-2">
          <div>
            <img src={ickAvatar} alt="" className="rounded-full w-[18px] h-[18px] bg-black" />
          </div>
          <div className="flex-1 flex flex-col gap-y-[4px] items-start align-top">
            <p className="font-semibold text-xs">Icky</p>
            <p className="text-xs text-black font-light leading-none">
              Wow, your outfit is so basic and boring. Do you even know how to dress?
            </p>
            <p className="text-gray-500 text-xs font-light">Reply</p>
          </div>
        </div>

        <div className="flex-1 flex bg-white drop-shadow-sm rounded-md max-h-[80px]">
          <div className="flex-1 p-1 text-xs flex items-center">
            <p className="border-[1px] p-2 rounded-xl leading-none">
              Everyone’s got their own style! I’m comfy in this and that’s what matters. But hey, fashion is all about experimenting. Maybe I’ll try something new next time.
            </p>
          </div>
          <div className="w-[80px]">
            <img src={drakePostingNo} alt="" className="rounded-r-md h-full w-full" />
          </div>
        </div>
        <div className="flex-1 flex bg-white drop-shadow-sm rounded-md max-h-[80px]">
          <div className="w-[80px]">
            <img src={drakePostingYes} alt="" className="rounded-l-md" />
          </div>
          <div className="flex-1 p-2 text-sm flex items-center">
            <p className="border-[1px] p-2 rounded-xl leading-none">
              At least I’m not stuck in the 2000s like you. Go hate somewhere else, loser. 🙂‍↕️
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

type LandingHeroProps = {
  nextSection: () => void;
};

function LandingHero(props: LandingHeroProps) {
  const { nextSection } = props;

  return (
    <div className="h-screen">
      <div className="flex flex-col items-center px-2">
        <div className="bg-center bg-cover bg-logo192 h-[64px] w-[64px]"></div>
        <h2 className="text-[48px] text-center font-extrabold leading-tight text-luckiest-guy-regular text-logo">
          Unleash your <br /> witty words
        </h2>
        <h2 className="text-center text-bold text-accent-content text-[14px]">
          with&nbsp;
          <span className="text-[18px] text-handjet text-transparent inline-block bg-gradient-to-r from-info to-accent-content bg-clip-text">Unfriendly Tools</span>
        </h2>
      </div>
      <div className="mt-8">
        <DrakePostingMeme />
      </div>
      <div className="mt-4">
        <h3 className="text-center block font-semibold text-transparent bg-gradient-to-r from-amber-500 to-pink-500 bg-clip-text">
          Stop wasting time with <span className="text-handjet text-transparent inline-block bg-gradient-to-r from-fuchsia-600 to-pink-600 bg-clip-text">icky</span> anymore.
        </h3>
        <h3 className="animate-bounce text-center block text-[40px] mt-6" onClick={nextSection}>
          ⬇️
        </h3>
      </div>
    </div>
  );
}

const buildDate = (() => {
  const buildDateString = (preval`module.exports = (new Date()).toISOString()`) as string;
  const buildDate = new Date(buildDateString);
  const formatter = new Intl.DateTimeFormat(undefined, {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
  });
  return formatter.format(buildDate);
})();

function LandingFooter() {
  return (
    <div className="pb-10 px-4 flex items-center align-items gap-x-[1px] text-xs text-gray-600 font-medium">
      <div>
        <a href="/tos.html">Privacy & terms</a>
      </div>
      <div>
        ﹒
      </div>
      <div>
        <a href="mailto:magiceurekastudio@gmail.com">Contact us</a>
      </div>
      <div>
        ﹒
      </div>
      <div>
        &lt;3 at {buildDate}
      </div>
    </div>
  );
}

type LandingWithoutLoginProps = {
  login: () => void;
};

function LandingWithoutLogin(props: LandingWithoutLoginProps) {
  const refDivider = createRef<HTMLDivElement>();
  const refFooter = createRef<HTMLDivElement>();
  const [showLogoInNavbar, setShowLogoInNavbar] = useState(false);

  // detect divider to change navbar icon
  useEffect(() => {
    if (!refDivider.current) {
      return;
    }

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        setShowLogoInNavbar(entry.isIntersecting);
      });
    });

    observer.observe(refDivider.current);

    return () => {
      if (refDivider.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.unobserve(refDivider.current);
      }
    };
  }, [refDivider]);

  const csNavbarLogo = classNames(
    'flex items-center font-bold text-handjet transition-opacity east-in-out delay-150',
    {
      'opacity-0': !showLogoInNavbar,
      'opacity-100': showLogoInNavbar,
    }
  );
  const csNavbarTryNow = classNames(
    'flex-none flex items-center w-[80px] transition-opacity east-in-out delay-150',
    {
      'opacity-0': showLogoInNavbar,
      'opacity-100': !showLogoInNavbar,
    },
  )

  return (
    <div className="min-h-screen w-screen max-w-screen bg-primary">
      {/* header navbar */}
      <div className="navbar h-[--nav-header-height] text-primary-content p-0 sticky top-0 z-[--z-index-menu] bg-primary drop-shadow-sm px-2">
        <div className="flex-1">
          <h1 className={csNavbarLogo}>
            <div className="bg-center bg-cover bg-logo192 h-[28px] w-[28px] mr-2"></div>
            Unfriendly Tools
          </h1>
        </div>
        <div className={csNavbarTryNow}>
          <button
            className="flex-1 btn btn-info border-none bg-gradient-to-r from-blue-500 to-transparent block outline-none min-h-[32px] h-[32px] px-2 py-0 text-accent-content font-medium"
            onClick={props.login}
          >
            Try Now
          </button>
        </div>
      </div>
      {/* content */}
      <LandingHero
        nextSection={() => {
          refFooter.current?.scrollIntoView({ behavior: 'smooth' })
        }}
      />
      <div ref={refDivider} className="w-full h-[2px] sticky top-0"></div>
      <StartUsingButtons
        initialOffset="var(--nav-header-height)"
        buttons={[
          { text: 'TikTok Engagement' },
          { text: 'Tinder Ick Detector' },
          { text: 'Unfriendly commentary' },
          { text: 'More...' },
        ]}
      />
      {/* footer */}
      <LandingFooter />
      <div ref={refFooter} className="w-full h-[2px]"></div>
    </div>
  );
}

export default function Landing() {
  const [showAfterLogin, setShowAfterLogin] = useState(false);

  return (
    <>
      {showAfterLogin && <LandingAfterLogin />}
      {!showAfterLogin && <LandingWithoutLogin login={() => {
        setShowAfterLogin(true);
      }} />}
    </>
  );
}